export const SETTINGS_MENU = [
  {
    id: 1,
    label: "About",
    navigate: "/settings/about",
    showLoaderOnOffline: true,
  },
  {
    id: 2,
    label: "Profile",
    navigate: "/settings/profile",
    showLoaderOnOffline: true,
  },
  {
    id: 3,
    label: "Feedback",
    mailTo: "mailto:feedback@empowerenergy.com.au",
    showLoaderOnOffline: true,
  },
  {
    id: 4,
    label: "Sign out",
    navigate: "/logout",
  },
];

export const CONTROL_OPTION = {
  SELF_POWERED: "default",
  SAVINGS_MAXIMISER: "optimisation",
  THIRD_PARTY_CONTROL: "vpp",
};

export const CONTROL_OPTIONS = [
  {
    label: "Self powered",
    value: CONTROL_OPTION.SELF_POWERED,
    description: "Maximise the amount of solar energy used to power your home",
    readMore: "read more",
    route: "self-powered",
  },
  {
    label: "Savings maximiser",
    value: CONTROL_OPTION.SAVINGS_MAXIMISER,
    description:
      "Your battery uses predicted solar and house usage to optimise savings for your electricity plan",
    readMore: "read more",
    route: "savings-maximiser",
  },
  {
    label: "Third-party Control",
    value: CONTROL_OPTION.THIRD_PARTY_CONTROL,
    description:
      "Allow your Elektrobank to be controlled by an external organisation",
    readMore: "read more",
    route: "third-party-control",
  },
];

export const SAVINGS_MAXIMISER_PLAN = {
  FLAT: "default_flat",
  TIMEOFUSE: "default_tou",
  OTHER: "other",
};

export const SAVINGS_MAXIMISER_PLANS_OPTIONS = [
  {
    label: "Flat",
    value: SAVINGS_MAXIMISER_PLAN.FLAT,
  },
  {
    label: "Time of Use",
    value: SAVINGS_MAXIMISER_PLAN.TIMEOFUSE,
  },
  {
    label: "Other",
    value: SAVINGS_MAXIMISER_PLAN.OTHER,
  },
];

export const getControlLabel = (value) => {
  const option = CONTROL_OPTIONS.find((it) => it.value === value);
  return option?.label ?? value;
};

export const getTariffItem = (item) => {
  let obj = {};
  if (item.source === SAVINGS_MAXIMISER_PLAN.FLAT) {
    obj = {
      value: item.id,
      label: "Flat",
      source: SAVINGS_MAXIMISER_PLAN.FLAT,
    };
  } else if (item.source === SAVINGS_MAXIMISER_PLAN.TIMEOFUSE) {
    obj = {
      value: item.id,
      label: "Time of Use",
      source: SAVINGS_MAXIMISER_PLAN.TIMEOFUSE,
    };
  }

  return obj;
};
