import { isEmpty } from "lodash";
import { LicenseInfo } from "@mui/x-license-pro";

export const licenseKey =
  "fbee24dbffc2afa7a0f31b65aaf48b2bT1JERVI6MjUyMDYsRVhQSVJZPTE2NTM0MzIyODcwMDAsS0VZVkVSU0lPTj0x";

export const setupXGrid = () => {
  LicenseInfo.setLicenseKey(licenseKey);
};

export const setDatabyOrder = (columns, order = []) => {
  if (isEmpty(order)) return columns;
  const list = [];
  order.forEach((item) => {
    if (typeof item === "string") {
      const field = columns.find((it) => it.field === item);
      field.showColumn = true;
      list.push(field);
    } else {
      const field = columns.find((it) => it.field === item.field);
      field["showColumn"] = item.showColumn;
      list.push(field);
    }
  });

  if (columns.length !== order.length) {
    columns.forEach((column) => {
      if (list.filter((it) => it.field === column.field).length === 0) {
        list.push({
          ...column,
          showColumn: true,
        });
      }
    });
  }

  return list;
};
