import { lazy } from "react";
import { Navigate, useRoutes } from "react-router-dom";
import { lazyRetry } from "utils/lazy";

import BasicLayout from "layout/BasicLayout";
import AuthLayout from "layout/AuthLayout";

const Login = lazy(() =>
  lazyRetry(() =>
    import(/* webpackChunkName: "Login" */ "./pages/Session/Login")
  )
);

const Logout = lazy(() =>
  lazyRetry(() =>
    import(/* webpackChunkName: "Logout" */ "./pages/Session/Logout")
  )
);

const ResetPassword = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: "ResetPassword" */ "./pages/Session/ResetPassword"
    )
  )
);

const ChangePassword = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: "ChangePassword" */ "./pages/Session/ChangePassword"
    )
  )
);

const FirstLogin = lazy(() =>
  lazyRetry(() =>
    import(/* webpackChunkName: "FirstLogin" */ "./pages/Session/FirstLogin")
  )
);

const NotFound = lazy(() =>
  lazyRetry(() => import(/* webpackChunkName: "NotFound" */ "./pages/NotFound"))
);

const Home = lazy(() =>
  lazyRetry(() => import(/* webpackChunkName: "Home" */ "./pages/Home"))
);

const History = lazy(() =>
  lazyRetry(() => import(/* webpackChunkName: "History" */ "./pages/History"))
);

const Summary = lazy(() =>
  lazyRetry(() => import(/* webpackChunkName: "Summary" */ "./pages/Summary"))
);
const Notifications = lazy(() =>
  lazyRetry(() =>
    import(/* webpackChunkName: "Notifications" */ "./pages/Notifications")
  )
);
const NotificationGuide = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: "NotificationGuide" */ "./pages/Notifications/NotificationGuide"
    )
  )
);
const NotificationDetails = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: "NotificationDetails" */ "./pages/Notifications/NotificationDetails"
    )
  )
);

const Settings = lazy(() =>
  lazyRetry(() => import(/* webpackChunkName: "Settings" */ "./pages/Settings"))
);

const SettingsSubmenu = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: "SettingsSubmenu" */ "./pages/Settings/SettingsSubmenu"
    )
  )
);

const About = lazy(() =>
  lazyRetry(() =>
    import(/* webpackChunkName: "About" */ "./pages/Settings/About")
  )
);

const Profile = lazy(() =>
  lazyRetry(() =>
    import(/* webpackChunkName: "Profile" */ "./pages/Settings/Profile")
  )
);

const ConfigureControlMode = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: "ConfigureControlMode" */ "./pages/ControlMode/ConfigureControlMode"
    )
  )
);

const SelfPowered = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: "SelfPowered" */ "./pages/ControlMode/SelfPowered"
    )
  )
);

const SavingsMaximiser = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: "SavingsMaximiser" */ "./pages/ControlMode/SavingsMaximiser"
    )
  )
);

const ThirdPartyControl = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: "ThirdPartyControl" */ "./pages/ControlMode/ThirdPartyControl"
    )
  )
);

export default function Router() {
  return useRoutes([
    {
      path: "/",
      element: <BasicLayout />,
      children: [
        { path: "", element: <Login /> },
        { path: "change-password", element: <ChangePassword /> },
        { path: "first-login", element: <FirstLogin /> },
        { path: "reset-password", element: <ResetPassword /> },
        { path: "logout", element: <Logout /> },
      ],
    },
    {
      path: "notFound",
      element: <AuthLayout />,
      children: [{ path: "", element: <NotFound pageTitle="Not Found" /> }],
    },
    {
      path: "/home",
      element: <AuthLayout />,
      children: [{ path: "", element: <Home pageTitle="Home" /> }],
    },
    {
      path: "/history",
      element: <AuthLayout />,
      children: [{ path: "", element: <History pageTitle="History" /> }],
    },
    {
      path: "/summary",
      element: <AuthLayout />,
      children: [{ path: "", element: <Summary pageTitle="Summary" /> }],
    },
    {
      path: "/notifications",
      element: <AuthLayout />,
      children: [
        { path: "", element: <Notifications pageTitle="Notifications" /> },
      ],
    },
    {
      path: "/notifications",
      element: <AuthLayout hideSiteSelection={true} />,
      children: [
        {
          path: "details/:id",
          element: <NotificationDetails />,
        },
        {
          path: "guide/:id",
          element: <NotificationGuide />,
        },
      ],
    },
    {
      path: "/settings",
      element: <AuthLayout />,
      children: [{ path: "", element: <Settings pageTitle="Settings" /> }],
    },
    {
      path: "/settings",
      element: <AuthLayout hideSiteSelection={true} />,
      children: [
        { path: "sub-menu/:id", element: <SettingsSubmenu /> },
        { path: "about", element: <About pageTitle="About" /> },
        { path: "profile", element: <Profile pageTitle="Profile" /> },
        {
          path: "configure-control-mode",
          element: <ConfigureControlMode pageTitle="Settings" />,
        },
        {
          path: "self-powered",
          element: <SelfPowered pageTitle="Settings" />,
        },
        {
          path: "savings-maximiser",
          element: <SavingsMaximiser pageTitle="Settings" />,
        },
        {
          path: "third-party-control",
          element: <ThirdPartyControl pageTitle="Settings" />,
        },
      ],
    },
    { path: "*", element: <Navigate to="/notFound" replace /> },
  ]);
}
