export const headerStyle = (theme) => {
  return {
    height: "72px",
    backgroundColor: theme.palette.primary.lighter,
    paddingLeft: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  };
};

export const footerStyle = (theme) => {
  const { palette } = theme;
  return {
    height: "64px",
    borderTop: `2px solid ${palette.primary.main}`,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    marginBottom: "8px",
    "& .footerItem": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: "70px",
    },
    "& .active": {
      opacity: "0.6",
    },
  };
};

export const contentStyle = () => {
  return {
    flex: "1",
    overflow: "auto",
    padding: "10px",
  };
};
