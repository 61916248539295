import { API } from "aws-amplify";

export const GET_OPTIONS_REQUEST = "@@option/GET_OPTIONS_REQUEST";
export const GET_OPTIONS_SUCCESS = "@@option/GET_OPTIONS_SUCCESS";
export const GET_OPTIONS_FAILURE = "@@option/GET_OPTIONS_FAILURE";

export function getOptions() {
  return async (dispatch) => {
    dispatch({
      type: GET_OPTIONS_REQUEST,
    });
    try {
      const response = await API.get("cmsAPI", "/option");
      dispatch({
        type: GET_OPTIONS_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_OPTIONS_FAILURE,
        payload: error,
      });
    }
  };
}
