import {
  GET_SUMMARY_REQUEST,
  GET_SUMMARY_SUCCESS,
  GET_SUMMARY_FAILURE,
} from "actions/summary";

const initialSummary = {
  battery_import: 0,
  battery_export: 0,
  solar: 0,
  grid_import: 0,
  grid_export: 0,
  house: 0,
  solar_gen: 0,
  grid_avoided: 0,
  peak_avoided: 0,
  battery_usage: 0,
  grid_usage: 0,
  solar_usage: 0,
  peak: 0,
  off_peak: 0,
  shoulder: 0,
  carbon_use: 0,
  car_use: 0,
  phone_use: 0,
  timestamp: "",
  peak_grid_imports_avoided: 0,
  tou_periods: [],
};

const initialState = {
  loading: false,
  error: {},
  data: initialSummary,
  dataLoaded: false,
};

export default function summary(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_SUMMARY_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_SUMMARY_SUCCESS: {
      const data =
        payload.length > 0
          ? {
              ...initialSummary,
              ...payload[0],
            }
          : initialSummary;
      return {
        ...state,
        loading: false,
        data,
        dataLoaded: true,
      };
    }

    case GET_SUMMARY_FAILURE: {
      return {
        ...state,
        loading: false,
        data: initialSummary,
      };
    }
    default:
      return state;
  }
}
