export const GET_VERSION_REQUEST = "@@version/GET_VERSION_REQUEST";
export const GET_VERSION_SUCCESS = "@@version/GET_VERSION_SUCCESS";
export const GET_VERSION_FAILURE = "@@version/GET_VERSION_FAILURE";

export function getVersion() {
  return async (dispatch) => {
    dispatch({
      type: GET_VERSION_REQUEST,
    });
    try {
      const response = await fetch("/version.json").then((r) => {
        return r.json();
      });

      dispatch({
        type: GET_VERSION_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_VERSION_FAILURE,
        payload: error,
      });
    }
  };
}

export const RECONCILE_VERSION = "@@version/RECONCILE_VERSION";

export function reconcileVersion(callback) {
  return async (dispatch) => {
    dispatch({
      type: RECONCILE_VERSION,
    });

    if (callback) {
      callback();
    }
  };
}
export const CLEAR_VERSION_AVAILABLE = "@@version/CLEAR_VERSION_AVAILABLE";

export function clearVersion() {
  return async (dispatch) => {
    dispatch({
      type: CLEAR_VERSION_AVAILABLE,
    });
  };
}
