import { useEffect, useState } from "react";
import { CssBaseline } from "@mui/material";
import { Suspense } from "react";
import Router from "./routes";
import ThemeConfig from "./theme";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import SessionCheck from "pages/Session/SessionCheck";
import { getProfile } from "actions/profile";
import {
  Loader,
  NewUpdateModal,
  NoNetworkModal,
} from "empower-energy-owner-components";
import { clearVersion, getVersion, reconcileVersion } from "actions/version";
import { checkStorage } from "actions/session";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { getError } from "utils/errors";

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      if (error.response.status !== 401) {
        getError(error.response.data);
      }
    } else if (error.request) {
      console.error("Network error or no response:", error.request);
    } else {
      console.error("Error setting up request:", error.message);
    }
    return Promise.reject(error);
  }
);

function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [showNoNetworkModal, setShowNoNetworkModal] = useState(false);

  const error = useSelector((state) => state.session.error);
  const user = useSelector((state) => state.session.user);
  const session = useSelector((state) => state.session.session);
  const isNewVersion = useSelector((state) => state.version?.isNewVersion);

  useEffect(() => {
    dispatch(checkStorage());
  }, []);

  useEffect(() => {
    setShowNoNetworkModal(!window.navigator.onLine);
  }, []);

  useEffect(() => {
    if (error?.code === "PasswordResetRequiredException") {
      navigate("/reset-password");
    }
  }, [error]);

  useEffect(() => {
    if (user?.challengeName === "NEW_PASSWORD_REQUIRED") {
      navigate("/first-login");
    } else {
      if (
        session &&
        ["/", "/login", "/first-login", "/change-password"].includes(
          location.pathname
        )
      ) {
        navigate("/home");
      }
    }
  }, [user, session]);

  useEffect(() => {
    if (session) {
      dispatch(getProfile());
    }
  }, [session, dispatch]);

  useEffect(() => {
    dispatch(getVersion());
    const interval = setInterval(() => {
      dispatch(getVersion());
    }, 1000 * 60 * 15);

    return () => {
      clearInterval(interval);
    };
  }, [dispatch]);

  const updateApp = () => {
    dispatch(
      reconcileVersion(() => {
        // Timeout required or updated state
        // is not stored before reload
        setTimeout(function () {
          window.location.reload();
        }, 100);
      })
    );
  };

  const clearNewVersion = () => {
    dispatch(clearVersion());
  };

  return (
    <ThemeConfig>
      {isNewVersion && (
        <NewUpdateModal
          open={isNewVersion}
          onClose={clearNewVersion}
          onClickRefresh={updateApp}
        />
      )}
      {showNoNetworkModal && (
        <NoNetworkModal
          open={showNoNetworkModal}
          onClose={() => setShowNoNetworkModal(false)}
        />
      )}
      <CssBaseline />
      <SessionCheck />
      <Suspense fallback={<Loader show={true} fullPage={true} />}>
        <Router />
      </Suspense>
      <ToastContainer
        autoClose={3000}
        pauseOnFocusLoss={false}
        pauseOnHover={false}
      />
    </ThemeConfig>
  );
}

export default App;
