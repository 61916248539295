export const SELECTED_SITE = "selectedSite";

export const setSelectedSite = (value) => {
  window.localStorage.setItem(SELECTED_SITE, value);
};

export const getSelectedSite = () => {
  return window.localStorage.getItem(SELECTED_SITE);
};

export const LAST_REFRESH_TIME = "lastRefreshTime";

export const setLastRefreshTime = (value) => {
  window.localStorage.setItem(LAST_REFRESH_TIME, value);
};

export const getLastRefreshTime = () => {
  return window.localStorage.getItem(LAST_REFRESH_TIME);
};

export const removeSession = () => {
  window.localStorage.removeItem(LAST_REFRESH_TIME);
};

export const CLEAR_STORAGE_TIME = "clearStorageTime";

export const setClearStorageTime = (value) => {
  window.localStorage.setItem(CLEAR_STORAGE_TIME, value);
};

export const getClearStorageTime = () => {
  return window.localStorage.getItem(CLEAR_STORAGE_TIME);
};

export const removeClearStorage = () => {
  window.localStorage.clear();
  const time = new Date().getTime();
  setClearStorageTime(time);
};
