import { API } from "aws-amplify";
import dayjs from "dayjs";

export const GET_PERFORMANCE_REQUEST = "@@performance/GET_PERFORMANCE_REQUEST";
export const GET_PERFORMANCE_SUCCESS = "@@performance/GET_PERFORMANCE_SUCCESS";
export const GET_PERFORMANCE_FAILURE = "@@performance/GET_PERFORMANCE_FAILURE";

export function getPerformance(id, window, dateRange) {
  return async (dispatch) => {
    if (!id) return;
    if (!dayjs(dateRange.start).isValid()) return;
    dispatch({
      type: GET_PERFORMANCE_REQUEST,
    });
    try {
      let qParams = `?window=${window}`;
      if (dateRange.start) {
        qParams = `${qParams}&start=${dateRange.start}`;
      }
      if (dateRange.end) {
        qParams = `${qParams}&end=${dateRange.end}`;
      }

      const response = await API.get("cmsAPI", `/performance/${id}${qParams}`);
      dispatch({
        type: GET_PERFORMANCE_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_PERFORMANCE_FAILURE,
        payload: error,
      });
    }
  };
}
