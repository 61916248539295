import { Box, Typography, Badge } from "@mui/material";
import { footerStyle } from "./styles";
import { House, Poll, PieChart, Email, Settings } from "@mui/icons-material";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

const OPTIONS = [
  { label: "Home", to: "/home", icon: <House /> },
  { label: "History", to: "/history", icon: <Poll /> },
  { label: "Summary", to: "/summary", icon: <PieChart /> },
  { label: "Notifications", to: "/notifications", icon: <Email /> },
  { label: "Settings", to: "/settings", icon: <Settings /> },
];

const Footer = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [anyUnackowledge, setAnyUnacknowledge] = useState(false);

  const notificationList = useSelector((state) => state.notification?.list);

  useEffect(() => {
    if (notificationList?.length > 0) {
      setAnyUnacknowledge(notificationList.some((it) => !it.acknowledged));
    } else {
      setAnyUnacknowledge(false);
    }
  }, [notificationList]);

  const onClick = (item) => {
    navigate(item.to);
  };

  const checkActive = (item) => {
    let className = "";
    if (location.pathname.includes(item.to)) {
      className = "active";
    }
    return className;
  };

  return (
    <Box sx={(theme) => footerStyle(theme)}>
      {OPTIONS.map((item, i) => (
        <Box
          key={i}
          className={`footerItem ${checkActive(item)}`}
          onClick={() => onClick(item)}
        >
          {anyUnackowledge && item.label === "Notifications" ? (
            <Badge color="error" variant="dot" badgeContent=" ">
              <div>{item.icon}</div>
            </Badge>
          ) : (
            <div>{item.icon}</div>
          )}
          <Typography variant="caption" mt={-0.5}>
            {item.label}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

export default Footer;
