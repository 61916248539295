import { capitalize } from "@mui/material";
import { toast } from "react-toastify";

export const IGNORED_MESSAGES = ["Internal Server Error"];

export const ERROR_KEYS = {
  first_name: "First Name",
  last_name: "Last Name",
  access_type: "User Role",
};

export const getError = (data) => {
  if (data?.message && IGNORED_MESSAGES.includes(data?.message)) {
    toast.error("Something went wrong!");
  } else {
    Object.keys(data).forEach((key) => {
      const errorKey = ERROR_KEYS[key] ?? key;
      if (typeof data[key] === "string") {
        const error = `${key === "message" ? "" : key} ${data[key]}`;
        toast.error(error);
      } else {
        const error = `${capitalize(errorKey)} - ${capitalize(
          data[key].join(", ")
        )}`;
        toast.error(error);
      }
    });
  }
};
