import { API } from "aws-amplify";
import { SETTINGS_MENU } from "constants/settings";
import { toast } from "react-toastify";

export const GET_SETTINGS_REQUEST = "@@settings/GET_SETTINGS_REQUEST";
export const GET_SETTINGS_SUCCESS = "@@settings/GET_SETTINGS_SUCCESS";
export const GET_SETTINGS_FAILURE = "@@settings/GET_SETTINGS_FAILURE";

export function getSettings(siteId) {
  return async (dispatch) => {
    if (!siteId) return;
    dispatch({
      type: GET_SETTINGS_REQUEST,
    });
    try {
      const response = await API.get("cmsAPI", `/site/${siteId}/setting`);
      dispatch({
        type: GET_SETTINGS_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_SETTINGS_FAILURE,
        payload: error,
      });
    }
  };
}

export const PUT_SETTINGS_REQUEST = "@@settings/PUT_SETTINGS_REQUEST";
export const PUT_SETTINGS_SUCCESS = "@@settings/PUT_SETTINGS_SUCCESS";
export const PUT_SETTINGS_FAILURE = "@@settings/PUT_SETTINGS_FAILURE";

export function updateSettings(siteId, value) {
  return async (dispatch) => {
    if (!siteId) return;
    dispatch({
      type: PUT_SETTINGS_REQUEST,
    });
    try {
      const init = {
        body: value,
      };
      const response = await API.put("cmsAPI", `/site/${siteId}/setting`, init);
      dispatch({
        type: PUT_SETTINGS_SUCCESS,
        payload: response,
      });

      dispatch(getSettings(siteId));
    } catch (error) {
      dispatch({
        type: PUT_SETTINGS_FAILURE,
        payload: error,
      });
    }
  };
}

export const PUT_SETTINGS_DOCUMENT_REQUEST =
  "@@settings/PUT_SETTINGS_DOCUMENT_REQUEST";
export const PUT_SETTINGS_DOCUMENT_SUCCESS =
  "@@settings/PUT_SETTINGS_DOCUMENT_SUCCESS";
export const PUT_SETTINGS_DOCUMENT_FAILURE =
  "@@settings/PUT_SETTINGS_DOCUMENT_FAILURE";

export function updateSettingsDocument(siteId, file, formData) {
  return async (dispatch) => {
    dispatch({
      type: PUT_SETTINGS_DOCUMENT_REQUEST,
    });
    try {
      let fileName = file?.name;
      let fileExtension = fileName?.split(".").pop();

      const response = await API.get(
        "cmsAPI",
        `/site/${siteId}/document_upload_url?content_type=${file.type}&file_extension=.${fileExtension}`
      );

      if (response.upload_url && file) {
        const form = new FormData();
        let url = response.upload_url.url;
        Object.keys(response.upload_url.fields).forEach((it) => {
          form.append(it, response.upload_url.fields[it]);
        });

        form.append("file", file);

        const requestOptions = {
          method: "POST",
          body: form,
        };
        const res = await fetch(url, requestOptions);

        if (!res.ok) {
          const error = "Document upload failed";
          toast.error(error);
          throw new Error(error);
        }

        dispatch(updateSettings(siteId, formData));
      }

      dispatch({
        type: PUT_SETTINGS_DOCUMENT_SUCCESS,
        payload: response,
      });

      toast.success("Document uploaded successfully!");

      dispatch(getSettings(siteId));
    } catch (error) {
      dispatch({
        type: PUT_SETTINGS_DOCUMENT_FAILURE,
        payload: error,
      });
    }
  };
}

export const GET_SETTINGS_MENU_REQUEST = "@@settings/GET_SETTINGS_MENU_REQUEST";
export const GET_SETTINGS_MENU_SUCCESS = "@@settings/GET_SETTINGS_MENU_SUCCESS";
export const GET_SETTINGS_MENU_FAILURE = "@@settings/GET_SETTINGS_MENU_FAILURE";

export function getSettingsMenuList() {
  return async (dispatch) => {
    dispatch({
      type: GET_SETTINGS_MENU_SUCCESS,
      payload: SETTINGS_MENU,
    });
  };
}

export const GET_SELECTED_SETTINGS_MENU_REQUEST =
  "@@settings/GET_SELECTED_SETTINGS_MENU_REQUEST";
export const GET_SELECTED_SETTINGS_MENU_SUCCESS =
  "@@settings/GET_SELECTED_SETTINGS_MENU_SUCCESS";
export const GET_SELECTED_SETTINGS_MENU_FAILURE =
  "@@settings/GET_SELECTED_SETTINGS_MENU_FAILURE";

export function getSelectedSettingsMenu(id) {
  return async (dispatch) => {
    let menu = SETTINGS_MENU.find((it) => it.id === Number(id));
    menu.childrens = SETTINGS_MENU.filter((it) => it.parentId === Number(id));

    dispatch({
      type: GET_SELECTED_SETTINGS_MENU_SUCCESS,
      payload: menu,
    });
  };
}

export const GET_TARIFF_LIST_REQUEST = "@@settings/GET_TARIFF_LIST_REQUEST";
export const GET_TARIFF_LIST_SUCCESS = "@@settings/GET_TARIFF_LIST_SUCCESS";
export const GET_TARIFF_LIST_FAILURE = "@@settings/GET_TARIFF_LIST_FAILURE";

export function getTariffList(siteId) {
  return async (dispatch) => {
    dispatch({
      type: GET_TARIFF_LIST_REQUEST,
    });
    try {
      const response = await API.get("cmsAPI", `/site/${siteId}/tariff`);

      dispatch({
        type: GET_TARIFF_LIST_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_TARIFF_LIST_FAILURE,
        payload: error,
      });
    }
  };
}
