import {
  GET_NOTIFICATION_LIST_REQUEST,
  GET_NOTIFICATION_LIST_SUCCESS,
  GET_NOTIFICATION_LIST_FAILURE,
  PUT_MULTI_NOTIFICATION_ACKNOWLEDGE_REQUEST,
  PUT_MULTI_NOTIFICATION_ACKNOWLEDGE_SUCCESS,
  PUT_MULTI_NOTIFICATION_ACKNOWLEDGE_FAILURE,
  PUT_NOTIFICATION_ACKNOWLEDGE_REQUEST,
  PUT_NOTIFICATION_ACKNOWLEDGE_SUCCESS,
  PUT_NOTIFICATION_ACKNOWLEDGE_FAILURE,
  DELETE_MULTI_NOTIFICATION_REQUEST,
  DELETE_MULTI_NOTIFICATION_SUCCESS,
  DELETE_MULTI_NOTIFICATION_FAILURE,
  DELETE_NOTIFICATION_REQUEST,
  DELETE_NOTIFICATION_SUCCESS,
  DELETE_NOTIFICATION_FAILURE,
  GET_NOTIFICATION_DETAILS_REQUEST,
  GET_NOTIFICATION_DETAILS_SUCCESS,
  GET_NOTIFICATION_DETAILS_FAILURE,
} from "actions/notification";

const initialState = {
  loading: false,
  error: {},
  list: [],
  details: {},
};

export default function notification(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_NOTIFICATION_LIST_REQUEST:
    case PUT_MULTI_NOTIFICATION_ACKNOWLEDGE_REQUEST:
    case PUT_NOTIFICATION_ACKNOWLEDGE_REQUEST:
    case DELETE_MULTI_NOTIFICATION_REQUEST:
    case DELETE_NOTIFICATION_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_NOTIFICATION_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        list: payload,
      };
    }

    case PUT_MULTI_NOTIFICATION_ACKNOWLEDGE_SUCCESS:
    case PUT_NOTIFICATION_ACKNOWLEDGE_SUCCESS:
    case DELETE_MULTI_NOTIFICATION_SUCCESS:
    case DELETE_NOTIFICATION_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }

    case GET_NOTIFICATION_DETAILS_REQUEST: {
      return {
        ...state,
        loading: true,
        details: {},
      };
    }

    case GET_NOTIFICATION_DETAILS_SUCCESS: {
      return {
        ...state,
        loading: false,
        details: payload,
      };
    }

    case GET_NOTIFICATION_DETAILS_FAILURE: {
      return {
        ...state,
        loading: false,
        details: {},
      };
    }

    case GET_NOTIFICATION_LIST_FAILURE:
    case PUT_MULTI_NOTIFICATION_ACKNOWLEDGE_FAILURE:
    case PUT_NOTIFICATION_ACKNOWLEDGE_FAILURE:
    case DELETE_MULTI_NOTIFICATION_FAILURE:
    case DELETE_NOTIFICATION_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
}
