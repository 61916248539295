import { Box, Typography } from "@mui/material";
import { headerStyle } from "./styles";
import { HeaderLogo } from "empower-energy-owner-components";
import { useSelector } from "react-redux";

const Header = () => {
  const page = useSelector((state) => state.page);

  return (
    <Box sx={(theme) => headerStyle(theme)}>
      <Typography variant="h2">{page?.pageTitle}</Typography>
      <HeaderLogo />
    </Box>
  );
};

export default Header;
