import { combineReducers } from "redux";
import session from "./session";
import page from "./page";
import profile from "./profile";
import performance from "./performance";
import site from "./site";
import summary from "./summary";
import notification from "./notification";
import settings from "./settings";
import option from "./option";
import version from "./version";

const rootReducer = combineReducers({
  session,
  page,
  profile,
  performance,
  site,
  summary,
  notification,
  settings,
  option,
  version,
});

export default rootReducer;
