import { Box, Tooltip, Typography, tooltipClasses } from "@mui/material";
import { useEffect, useState } from "react";
import {
  YAXIS_WIDTH,
  getChartTitle,
  getHourTime,
  getTimePeriods,
} from "./chartUtils";
import styled from "@emotion/styled";

const styles = (season) => {
  const bars = season?.days_of_week?.length;

  return {
    p: 1,
    pb: 1.5,
    mb: 2,
    backgroundColor: "white",
    "& .chart-wrapper": {
      position: "relative",
      minHeight: bars === 1 ? "90px" : "160px",
    },
    "& .chart-background": {
      height: "100%",
      width: `calc(100% - ${YAXIS_WIDTH})`,
      position: "absolute",
      left: YAXIS_WIDTH,
      borderLeft: "2px solid #ccc",
      display: "flex",
      "&-bar": {
        position: "absolute",
        height: "100%",
        width: "1px",
        borderRight: "1px solid #e9e9e9",
        "&-label": {
          width: "10px",
          position: "absolute",
          bottom: "-15px",
          left: "-5px",
          textAlign: "center",
          transform: "rotate(330deg)",
        },
      },
    },
    "& .chart-section": {
      position: "absolute",
      bottom: "10px",
      width: "100%",
    },
    "& .bar-chart-container": {
      display: "flex",
      alignItems: "center",
      height: "72px",
      "& .bar-chart-name": {
        fontSize: "12px",
        width: `calc(${YAXIS_WIDTH} + 14px)`,
        maxWidth: `calc(${YAXIS_WIDTH} + 14px)`,
      },
      "& .bar-chart": {
        width: "100%",
        height: "36px",
        display: "flex",
        "&-time-period": {
          px: "2px",
          color: "white",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          "&-content": {
            textShadow: "1px 1px black",
            overflow: "hidden",
            textOverflow: "ellipsis",
          },
        },
      },
    },
  };
};

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    width: 220,
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
    padding: "6px 12px",
  },
}));

const ChartBackground = () => {
  const bars = Array.from({ length: 24 }, () => "");
  return (
    <Box className="chart-background">
      {bars.map((bar, i) => {
        if (i % 2 !== 0) return;
        return (
          <Box
            key={i}
            className="chart-background-bar"
            sx={{
              left: `${(i / 24) * 100}%`,
            }}
          >
            <Typography
              className="chart-background-bar-label"
              fontSize="10px"
              fontWeight={500}
            >
              {getHourTime(i)}
            </Typography>
          </Box>
        );
      })}
    </Box>
  );
};

const ChartSection = ({ season, tariffColors }) => {
  const [list, setList] = useState([]);

  useEffect(() => {
    if (season.days_of_week) {
      const list = [];
      season.days_of_week.forEach((it) => {
        list.push({
          name: it.name,
          timePeriods: getTimePeriods(it),
        });
      });

      setList(list);
    }
  }, [season]);

  return (
    <Box className="chart-section">
      {list &&
        list.map((item, i) => (
          <BarChart key={i} item={item} tariffColors={tariffColors} />
        ))}
    </Box>
  );
};

const BarChart = ({ item, tariffColors }) => {
  return (
    <Box className="bar-chart-container">
      <Typography className="bar-chart-name">{item.name}</Typography>
      <Box className="bar-chart">
        {item.timePeriods.map((timePeriod, index) => (
          <TimePeriod
            key={index}
            timePeriod={timePeriod}
            tariffColors={tariffColors}
          />
        ))}
      </Box>
    </Box>
  );
};

const TimePeriod = ({ timePeriod, tariffColors }) => {
  const width = `${(timePeriod.count / 24) * 100}%`;
  return (
    <HtmlTooltip
      title={
        <Box>
          <Typography variant="subtitle1" color={tariffColors[timePeriod.name]}>
            {timePeriod.name}
          </Typography>
          <Box className="items-center">
            <Box
              sx={{
                width: "10px",
                height: "10px",
                bgcolor: tariffColors[timePeriod.name],
                borderRadius: "50%",
                mr: 1,
              }}
            ></Box>
            <Typography variant="body2">{`${getHourTime(
              timePeriod.start_hour
            )} - ${getHourTime(timePeriod.end_hour)}`}</Typography>
          </Box>
        </Box>
      }
      placement="top"
      arrow
      enterTouchDelay={0}
      slotProps={{
        popper: {
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, -14],
              },
            },
          ],
        },
      }}
    >
      <Box
        className="bar-chart-time-period"
        sx={{ width: width, bgcolor: tariffColors[timePeriod.name] }}
      >
        <Typography className="bar-chart-time-period-content" variant="caption">
          {timePeriod.name}
        </Typography>
      </Box>
    </HtmlTooltip>
  );
};

const TariffChart = ({ season, tariffColors }) => {
  const [title, setTitle] = useState("");

  useEffect(() => {
    setTitle(getChartTitle(season));
  }, [season]);

  return (
    <Box sx={() => styles(season)}>
      <Typography align="center" variant="body1">
        {title}
      </Typography>

      <Box my={1} className="chart-wrapper">
        <ChartBackground />
        <ChartSection season={season} tariffColors={tariffColors} />
      </Box>
    </Box>
  );
};

export default TariffChart;
