import {
  GET_OPTIONS_REQUEST,
  GET_OPTIONS_SUCCESS,
  GET_OPTIONS_FAILURE,
} from "actions/option";

const initialState = {
  loading: false,
  vpp: [],
  retailer: [],
  error: {},
};

export default function option(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_OPTIONS_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_OPTIONS_SUCCESS: {
      return {
        ...state,
        loading: false,
        ...payload,
      };
    }

    case GET_OPTIONS_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    default:
      return state;
  }
}
