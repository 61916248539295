import {
  SET_PAGE_TITLE,
  SET_WINDOW,
  GET_WINDOW,
  SET_FROM_TO_DATE,
  GET_FROM_TO_DATE,
  RESET_DATES,
} from "actions/page";
import { cloneDeep, isEqual } from "lodash";
import dayjs from "dayjs";

const initialDate = {
  start: dayjs(new Date()).format("YYYY-M-D"),
  end: dayjs(new Date()).format("YYYY-M-D"),
};

const initialState = {
  pageTitle: "",
  window: "day",
  fromToDate: initialDate,
  day: initialDate,
  week: initialDate,
  month: initialDate,
  year: initialDate,
};

export default function page(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_PAGE_TITLE: {
      return {
        ...state,
        pageTitle: payload,
      };
    }

    case GET_WINDOW: {
      return {
        ...state,
      };
    }

    case SET_WINDOW: {
      return {
        ...state,
        window: payload,
      };
    }

    case GET_FROM_TO_DATE: {
      return {
        ...state,
      };
    }

    case SET_FROM_TO_DATE: {
      const window = state.window;
      let fromToDate = cloneDeep(payload);
      if (window === "week") {
        fromToDate.start = dayjs(fromToDate?.start)
          .startOf("week")
          .add(1, "day")
          .format("YYYY-M-D");
        fromToDate.end = dayjs(fromToDate?.start)
          .endOf("week")
          .add(1, "day")
          .format("YYYY-M-D");
      } else {
        fromToDate.start = dayjs(fromToDate.start)
          .startOf(window)
          .format("YYYY-M-D");
        fromToDate.end = dayjs(fromToDate.start)
          .endOf(window)
          .format("YYYY-M-D");
      }
      if (isEqual(fromToDate, state.fromToDate)) {
        return {
          ...state,
        };
      } else {
        return {
          ...state,
          fromToDate: fromToDate,
          [state.window]: fromToDate,
        };
      }
    }

    case RESET_DATES: {
      return {
        ...state,
        fromToDate: initialDate,
        day: initialDate,
        week: initialDate,
        month: initialDate,
        year: initialDate,
      };
    }

    default:
      return state;
  }
}
