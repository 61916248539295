/* eslint-disable no-undef */
import React from "react";
import ReactDOM from "react-dom/client";
import Amplify, { Auth } from "aws-amplify";
import App from "./App";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { BrowserRouter } from "react-router-dom";
import configureStore from "./store";
import { setupXGrid } from "./utils/xGrid";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import "./index.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Provider as RollbarProvider, ErrorBoundary } from "@rollbar/react";

const { persistor, store } = configureStore();

const root = ReactDOM.createRoot(document.getElementById("root"));

setupXGrid();

const rollbarConfig = {
  accessToken: process.env.REACT_APP_ROLLBAR_CONFIG,
  environment: process.env.REACT_APP_ROLLBAR_ENVIRONMENT,
};

Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_AUTH_REGION,
    userPoolId: process.env.REACT_APP_AUTH_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_AUTH_USER_POOL_WEB_CLIENT_ID,
    mandatorySignIn: true,
  },
  API: {
    endpoints: [
      {
        name: "cmsAPI",
        endpoint: process.env.REACT_APP_API_ENDPOINT,
        custom_header: async () => {
          // return { Authorization : 'token' }
          // Alternatively, with Cognito User Pools use this:
          return {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getIdToken()
              .getJwtToken()}`,
          };
          // return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
        },
      },
    ],
  },
});

root.render(
  <RollbarProvider config={rollbarConfig}>
    <ErrorBoundary>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </ErrorBoundary>
  </RollbarProvider>
);

serviceWorkerRegistration.unregister();

reportWebVitals();
