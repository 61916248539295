import { API } from "aws-amplify";

export const GET_SITE_DETAILS_REQUEST = "@@site/GET_SITE_DETAILS_REQUEST";
export const GET_SITE_DETAILS_SUCCESS = "@@site/GET_SITE_DETAILS_SUCCESS";
export const GET_SITE_DETAILS_FAILURE = "@@site/GET_SITE_DETAILS_FAILURE";

export function getSiteDetails(id) {
  return async (dispatch) => {
    if (!id) return;
    dispatch({
      type: GET_SITE_DETAILS_REQUEST,
    });
    try {
      const response = await API.get("cmsAPI", `/site/${id}`);
      dispatch({
        type: GET_SITE_DETAILS_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_SITE_DETAILS_FAILURE,
        payload: error,
      });
    }
  };
}

export const CLEAR_SITES = "@@site/CLEAR_SITES";

export function clearSites() {
  return async (dispatch) => {
    dispatch({
      type: CLEAR_SITES,
    });
  };
}
