import { Outlet } from "react-router-dom";

const BasicLayout = () => {
  return (
    <>
      <Outlet />
    </>
  );
};

export default BasicLayout;
