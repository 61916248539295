import {
  GET_SETTINGS_REQUEST,
  GET_SETTINGS_SUCCESS,
  GET_SETTINGS_FAILURE,
  PUT_SETTINGS_REQUEST,
  PUT_SETTINGS_SUCCESS,
  PUT_SETTINGS_FAILURE,
  GET_SETTINGS_MENU_SUCCESS,
  GET_SETTINGS_MENU_FAILURE,
  GET_SELECTED_SETTINGS_MENU_SUCCESS,
  GET_TARIFF_LIST_REQUEST,
  GET_TARIFF_LIST_SUCCESS,
  GET_TARIFF_LIST_FAILURE,
  PUT_SETTINGS_DOCUMENT_REQUEST,
  PUT_SETTINGS_DOCUMENT_SUCCESS,
  PUT_SETTINGS_DOCUMENT_FAILURE,
} from "actions/settings";
import { getTariffItem, SAVINGS_MAXIMISER_PLAN } from "constants/settings";

const initialMenu = {
  id: 0,
  label: "",
  childrens: [],
};

const initialState = {
  loading: false,
  error: {},
  data: {},
  menuList: [],
  selectedMenu: initialMenu,
  tariffList: [],
  tariffListLoading: false,
};

export default function settings(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_SETTINGS_REQUEST:
    case PUT_SETTINGS_REQUEST:
    case PUT_SETTINGS_DOCUMENT_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_SETTINGS_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: payload,
      };
    }

    case PUT_SETTINGS_SUCCESS:
    case PUT_SETTINGS_DOCUMENT_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }

    case GET_SETTINGS_MENU_SUCCESS: {
      return {
        ...state,
        menuList: payload,
      };
    }

    case GET_SELECTED_SETTINGS_MENU_SUCCESS: {
      return {
        ...state,
        selectedMenu: payload,
      };
    }

    case GET_SETTINGS_FAILURE:
    case PUT_SETTINGS_FAILURE:
    case GET_SETTINGS_MENU_FAILURE:
    case PUT_SETTINGS_DOCUMENT_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case GET_TARIFF_LIST_REQUEST: {
      return {
        ...state,
        tariffListLoading: true,
      };
    }

    case GET_TARIFF_LIST_SUCCESS: {
      const list = payload
        .filter((it) => it.source !== "custom")
        .map((it) => {
          return {
            ...it,
            ...getTariffItem(it),
          };
        });

      const customTariff = payload.find((it) => it.source === "custom");
      list.push({
        ...customTariff,
        value: customTariff ? customTariff.id : 0,
        label: "Other",
        source: SAVINGS_MAXIMISER_PLAN.OTHER,
      });
      return {
        ...state,
        tariffList: list,
        tariffListLoading: false,
      };
    }

    case GET_TARIFF_LIST_FAILURE: {
      return {
        ...state,
        tariffListLoading: false,
      };
    }

    default:
      return state;
  }
}
