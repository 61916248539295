import { API } from "aws-amplify";

export const GET_NOTIFICATION_LIST_REQUEST =
  "@@notification/GET_NOTIFICATION_LIST_REQUEST";
export const GET_NOTIFICATION_LIST_SUCCESS =
  "@@notification/GET_NOTIFICATION_LIST_SUCCESS";
export const GET_NOTIFICATION_LIST_FAILURE =
  "@@notification/GET_NOTIFICATION_LIST_FAILURE";

export function getNotificationList(siteId) {
  return async (dispatch) => {
    if (!siteId) return;
    dispatch({
      type: GET_NOTIFICATION_LIST_REQUEST,
    });
    try {
      const response = await API.get("cmsAPI", `/site/${siteId}/notification`);
      dispatch({
        type: GET_NOTIFICATION_LIST_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_NOTIFICATION_LIST_FAILURE,
        payload: error,
      });
    }
  };
}

export const GET_NOTIFICATION_DETAILS_REQUEST =
  "@@notification/GET_NOTIFICATION_DETAILS_REQUEST";
export const GET_NOTIFICATION_DETAILS_SUCCESS =
  "@@notification/GET_NOTIFICATION_DETAILS_SUCCESS";
export const GET_NOTIFICATION_DETAILS_FAILURE =
  "@@notification/GET_NOTIFICATION_DETAILS_FAILURE";

export function getNotificationDetails(siteId, id) {
  return async (dispatch) => {
    if (!siteId) return;
    dispatch({
      type: GET_NOTIFICATION_DETAILS_REQUEST,
    });
    try {
      const response = await API.get(
        "cmsAPI",
        `/site/${siteId}/notification/${id}`
      );
      dispatch({
        type: GET_NOTIFICATION_DETAILS_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_NOTIFICATION_DETAILS_FAILURE,
        payload: error,
      });
    }
  };
}

export const PUT_MULTI_NOTIFICATION_ACKNOWLEDGE_REQUEST =
  "@@notification/PUT_MULTI_NOTIFICATION_ACKNOWLEDGE_REQUEST";
export const PUT_MULTI_NOTIFICATION_ACKNOWLEDGE_SUCCESS =
  "@@notification/PUT_MULTI_NOTIFICATION_ACKNOWLEDGE_SUCCESS";
export const PUT_MULTI_NOTIFICATION_ACKNOWLEDGE_FAILURE =
  "@@notification/PUT_MULTI_NOTIFICATION_ACKNOWLEDGE_FAILURE";

export function acknowledgeMultiNotifications(siteId, list, callback) {
  return async (dispatch) => {
    if (!siteId) return;
    dispatch({
      type: PUT_MULTI_NOTIFICATION_ACKNOWLEDGE_REQUEST,
    });
    try {
      const init = {
        body: {
          acknowledged: list,
        },
      };
      const response = await API.put(
        "cmsAPI",
        `/site/${siteId}/notification`,
        init
      );
      dispatch({
        type: PUT_MULTI_NOTIFICATION_ACKNOWLEDGE_SUCCESS,
        payload: response,
      });
      dispatch(getNotificationList(siteId));
      if (callback) {
        callback();
      }
    } catch (error) {
      dispatch({
        type: PUT_MULTI_NOTIFICATION_ACKNOWLEDGE_FAILURE,
        payload: error,
      });
    }
  };
}

export const PUT_NOTIFICATION_ACKNOWLEDGE_REQUEST =
  "@@notification/PUT_NOTIFICATION_ACKNOWLEDGE_REQUEST";
export const PUT_NOTIFICATION_ACKNOWLEDGE_SUCCESS =
  "@@notification/PUT_NOTIFICATION_ACKNOWLEDGE_SUCCESS";
export const PUT_NOTIFICATION_ACKNOWLEDGE_FAILURE =
  "@@notification/PUT_NOTIFICATION_ACKNOWLEDGE_FAILURE";

export function acknowledgeNotification(siteId, id, callback) {
  return async (dispatch) => {
    if (!siteId) return;
    dispatch({
      type: PUT_NOTIFICATION_ACKNOWLEDGE_REQUEST,
    });
    try {
      const init = {
        body: {
          acknowledged: true,
        },
      };
      const response = await API.put(
        "cmsAPI",
        `/site/${siteId}/notification/${id}`,
        init
      );
      dispatch({
        type: PUT_NOTIFICATION_ACKNOWLEDGE_SUCCESS,
        payload: response,
      });

      if (callback) {
        callback();
      } else {
        dispatch(getNotificationList(siteId));
      }
    } catch (error) {
      dispatch({
        type: PUT_NOTIFICATION_ACKNOWLEDGE_FAILURE,
        payload: error,
      });
    }
  };
}

export const DELETE_MULTI_NOTIFICATION_REQUEST =
  "@@notification/DELETE_MULTI_NOTIFICATION_REQUEST";
export const DELETE_MULTI_NOTIFICATION_SUCCESS =
  "@@notification/DELETE_MULTI_NOTIFICATION_SUCCESS";
export const DELETE_MULTI_NOTIFICATION_FAILURE =
  "@@notification/DELETE_MULTI_NOTIFICATION_FAILURE";

export function deleteMultiNotifications(siteId, list, callback) {
  return async (dispatch) => {
    if (!siteId) return;
    dispatch({
      type: DELETE_MULTI_NOTIFICATION_REQUEST,
    });
    try {
      const init = {
        body: {
          deleted: list,
        },
      };
      const response = await API.del(
        "cmsAPI",
        `/site/${siteId}/notification`,
        init
      );
      dispatch({
        type: DELETE_MULTI_NOTIFICATION_SUCCESS,
        payload: response,
      });
      dispatch(getNotificationList(siteId));
      if (callback) {
        callback();
      }
    } catch (error) {
      dispatch({
        type: DELETE_MULTI_NOTIFICATION_FAILURE,
        payload: error,
      });
    }
  };
}

export const DELETE_NOTIFICATION_REQUEST =
  "@@notification/DELETE_NOTIFICATION_REQUEST";
export const DELETE_NOTIFICATION_SUCCESS =
  "@@notification/DELETE_NOTIFICATION_SUCCESS";
export const DELETE_NOTIFICATION_FAILURE =
  "@@notification/DELETE_NOTIFICATION_FAILURE";

export function deleteNotification(siteId, id, callback) {
  return async (dispatch) => {
    if (!siteId) return;
    dispatch({
      type: DELETE_NOTIFICATION_REQUEST,
    });
    try {
      const response = await API.del(
        "cmsAPI",
        `/site/${siteId}/notification/${id}`
      );
      dispatch({
        type: DELETE_NOTIFICATION_SUCCESS,
        payload: response,
      });
      if (callback) {
        callback();
      } else {
        dispatch(getNotificationList(siteId));
      }
    } catch (error) {
      dispatch({
        type: DELETE_NOTIFICATION_FAILURE,
        payload: error,
      });
    }
  };
}
