import { ChevronLeft } from "@mui/icons-material";
import { Box, IconButton, Typography } from "@mui/material";

const BackLink = ({ title, onClick }) => {
  return (
    <Box onClick={onClick} className="flex items-center" mb={1}>
      <IconButton>
        <ChevronLeft />
      </IconButton>
      <Box className="flex-grow text-center">
        <Typography ml={-4} variant="subtitle1">
          {title}
        </Typography>
      </Box>
    </Box>
  );
};

export default BackLink;
