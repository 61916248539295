/* eslint-disable no-unused-vars */
import { API } from "aws-amplify";
import dayjs from "dayjs";

export const GET_SUMMARY_REQUEST = "@@summary/GET_SUMMARY_REQUEST";
export const GET_SUMMARY_SUCCESS = "@@summary/GET_SUMMARY_SUCCESS";
export const GET_SUMMARY_FAILURE = "@@summary/GET_SUMMARY_FAILURE";

export function getSummary(id, window, dateRange) {
  return async (dispatch) => {
    if (!id) return;
    if (!dayjs(dateRange.start).isValid()) return;
    dispatch({
      type: GET_SUMMARY_REQUEST,
    });
    try {
      let qParams = `?window=${window}`;
      if (dateRange.start) {
        qParams = `${qParams}&start=${dateRange.start}`;
      }
      if (dateRange.end) {
        qParams = `${qParams}&end=${dateRange.end}`;
      }

      const response = await API.get("cmsAPI", `/summary/${id}${qParams}`);

      dispatch({
        type: GET_SUMMARY_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_SUMMARY_FAILURE,
        payload: error,
      });
    }
  };
}
