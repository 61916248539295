import {
  GET_PROFILE_DETAIL_REQUEST,
  GET_PROFILE_DETAIL_SUCCESS,
  GET_PROFILE_DETAIL_FAILURE,
  SET_SELECTED_SITE,
  CLEAR_PROFILE,
} from "actions/profile";

import { setSelectedSite, getSelectedSite } from "utils/localStorage";

const initialState = {
  loading: false,
  error: {},
  about: {},
  profile: {},
  sites: [],
  selectedSite: "",
};

export default function profile(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_PROFILE_DETAIL_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_PROFILE_DETAIL_SUCCESS: {
      const sites = payload?.sites.map((it) => {
        return {
          ...it,
          value: it.site,
          label: it.name || "Site Name Not Available",
        };
      });
      let selectedSite = getSelectedSite();
      selectedSite = sites.length > 0 ? selectedSite : "";
      if (
        (!selectedSite && sites.length > 0) ||
        sites.filter((it) => it.value === selectedSite).length === 0
      ) {
        selectedSite = sites[0].value;
        setSelectedSite(selectedSite);
      } else if (sites.length === 0) {
        selectedSite = "";
        setSelectedSite(selectedSite);
      }
      return {
        ...state,
        loading: false,
        about: payload?.about,
        profile: payload?.profile,
        sites,
        selectedSite,
      };
    }

    case SET_SELECTED_SITE: {
      setSelectedSite(payload);
      return {
        ...state,
        selectedSite: payload,
      };
    }

    case GET_PROFILE_DETAIL_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case CLEAR_PROFILE: {
      return {
        ...initialState,
      };
    }

    default:
      return state;
  }
}
