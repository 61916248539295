import {
  GET_PERFORMANCE_REQUEST,
  GET_PERFORMANCE_SUCCESS,
  GET_PERFORMANCE_FAILURE,
} from "actions/performance";

const initialState = {
  loading: false,
  error: {},
  data: [],
  dataLoaded: false,
};

export default function performance(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_PERFORMANCE_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_PERFORMANCE_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: payload ? payload : [],
        dataLoaded: true,
      };
    }

    case GET_PERFORMANCE_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
}
